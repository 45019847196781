<template lang="pug">
v-list-tile.list-item.orange.lighten-4(
  v-if="item.schedType === 'team'"
)
  span.font-weight-black.display-2 {{ item.team.name }}
</template>
<script>

export default {
  props: ['item']
}
</script>

