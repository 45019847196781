<template lang="pug">
div
    v-select.pl-3(
    label="team"
    v-model="selected"
    :items="items"
    item-text='name'
    return-object
    @change="changeTeam"
    dense

    )
</template>

<script>
export default {
  props: ['_idProductionInstance', 'team'],
  data: () => ({
    selected: {}
  }),
  computed: {
    items () {
      return this.$store.getters['dashboards/teams']
    }
  },
  methods: {
    changeTeam () {
      const items = this.$store.getters['productionInstances/items']
      const pi = items.find(x => x._id === this._idProductionInstance)
      // check for remove team
      if (this.selected.name === 'remove team') {
        this.selected = {}
      }
      pi.team = this.selected
      this.$store.commit('productionInstances/itemsUpdate', { list: 'items', item: pi })
      this.$store.commit('productionInstances/item', pi)
      this.$store.dispatch('productionInstances/_UPDATE')
    }
  },
  watch: {
    team: {
      handler () {
        this.selected = this.team
      },
      immediate: true,
      deep: true
    }
  }
}
</script>
