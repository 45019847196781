import moment from 'moment'
import { ProductionInstance } from '@/models'

export default (inputList, lineTypes) => {
  let items = inputList.map(x => x)
  if (lineTypes.includes('date')) { items = addDates(items) }
  if (lineTypes.includes('team')) { items = addTeams(items) }

  return items
}

function addDates (items) {
  if (items.length < 1) { return items }

  // make the first date item
  const firstDate = dateInstance(items[0], items)
  items.splice(0, 0, firstDate)

  let previousDate = firstDate
  for (const item of items) {
    const prev = previousDate.dates.schedule
    const curr = item.dates.schedule
    if (prev !== curr) {
      const index = items.findIndex(x => x._id === item._id)
      const nextDate = dateInstance(items[index], items)
      items.splice(index, 0, nextDate)
      previousDate = nextDate
    }
  }

  return items
}

function dateInstance (item, items) {
  const $date = item.dates.schedule
  const instance = new ProductionInstance({
    schedType: 'date',
    dates: { schedule: $date }
  })
  const profititems = items.filter((x) => x.dates.schedule === $date).map((x) => x.decorationcost)

  instance.profit = profititems.reduce((pre, curr) => pre + curr)

  if ($date === '') {
    instance._tempDate = 'unscheduled'
    return instance
  } else {
    instance._tempDate = moment($date).format('LL')
    return instance
  }
}

function addTeams (items) {
  if (items.length < 1) { return items }

  // make the first date item
  // const firstDate = dateInstance(items[0].dates.schedule)
  // items.splice(0, 0, firstDate)

  let prev = 'first'
  function spliceTeam (item, items) {
    const index = items.findIndex(x => x._id === item._id)
    const nextTeam = teamInstance(items[index].team)
    items.splice(index, 0, nextTeam)
    return nextTeam
  }

  for (const item of items) {
    const curr = item.team._id
    const isFirstItem = prev === 'first'
    if (isFirstItem && curr) {
      prev = spliceTeam(item, items).team._id
    }
    if (prev !== curr && curr && item.schedType === 'line') {
      prev = spliceTeam(item, items).team._id
    } else {
      prev = item.team._id
    }
  }
  return items
}

function teamInstance (team) {
  const instance = new ProductionInstance({
    schedType: 'team',
    team
  })

  return instance
}
