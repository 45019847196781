<template lang="pug">
div
  ScheduleHeader

  v-container(fluid mt-3 px-0)
    v-list(dense two-line).pa-0
      div(
        v-for='(item, i) in itemsSorted'
        :key='item._id || i'
      )
        TaskTile(v-if='item.task.isTask' :item='item')
        LineTile(v-if="item.schedType === 'line' && !item.task.isTask" :item='item')
        DateTile(v-if="item.schedType === 'date'" :item='item')
        TeamTile(v-if="item.schedType === 'team'" :item='item')


</template>
<script>
import TaskTile from '../../components/tasktile'
import LineTile from '../../components/linetile'
import DateTile from '../../components/datetile'
import TeamTile from '../../components/teamtile'
import ScheduleHeader from '../../components/scheduleheader'
import scheduleSort from '../../sort'
import { mapFields } from 'vuex-map-fields'

export default {
  components: {
    TaskTile,
    LineTile,
    DateTile,
    TeamTile,
    ScheduleHeader,
  },
  computed: {
    ...mapFields('productionInstances', { items: 'items' }),
    itemsSorted () {
      return scheduleSort(this.items)
    },
  },
  async created () {
    await this.$store.dispatch('scheduling/_LOAD_SCHEDULE')
  }
}
</script>

