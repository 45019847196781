<template lang="pug">
v-dialog(
  ref="dialogScheduleDate"
  v-model="modal"
  width="290px"
)

  template(v-slot:activator="{ on }")
    v-icon.pa-3(
      v-on="on"
    ) event
  v-date-picker(scrollable v-model="input" @change="changeDate")
  v-card(flat)
    v-btn(@click='clearDate') clear date
    v-layout(row)
      v-flex {{ pi.dashboard.designName }} - {{pi._id}}
</template>

<script>
import { ProductionInstance } from '@/models'
export default {
  props: {
    pi: { type: Object, required: true }
  },
  data: () => ({
    input: null,
    temp: null,
    modal: false
  }),
  methods: {
    clearDate () {
      this.input = ''
      this.changeDate()
    },
    changeDate () {
      const item = this.temp
      item.dates.schedule = this.input
      this.$store.dispatch('productionInstances/updateOne', {
        data: {
          _id: item._id,
          'dates.schedule': item.dates.schedule
        }
      })


      this.$store.commit('productionInstances/itemsUpdate', { list: 'items', item })
      this.modal = false
    }
  },
  watch: {
    pi: {
      handler () {
        this.temp = new ProductionInstance(this.pi)
        this.input = this.temp.dates.schedule
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
