<template lang="pug">
v-select.pl-3(
  label="priority"
  v-model.number="input"
  :items="items"
  @change="changePriority"
  dense
)
</template>

<script>
import { ProductionInstance } from '@/models'
export default {
  props: ['pi'],
  data: () => ({
    items: [1, 2, 3, 4, 5],
    input: null,
    temp: null
  }),
  methods: {
    changePriority () {
      const item = this.temp
      item.dashboard.priority.production = this.input
      this.$store.commit('productionInstances/item', item)
      this.$store.dispatch('productionInstances/_UPDATE')
      this.$store.commit('productionInstances/itemsUpdate', { list: 'items', item })
    }
  },
  created () {
    this.temp = new ProductionInstance(this.pi)
    this.input = this.temp.dashboard.priority.production
  }
}
</script>
