import addLines from './add-lines'

export default (inputList) => {
  const items = inputList.map(x => x)

  // first sort items by date and then priority
  let itemsSorted = items.sort((a, b) => {
    const A = a._sortSchedule
    const B = b._sortSchedule
    if (A > B) return 1
    if (A < B) return -1
    return 0
  })

  itemsSorted = itemsSorted.map(item => {
    item.schedType = 'line'
    return item
  })

  /// /////
  // prepare date subsummary lines
  /// /////

  itemsSorted = addLines(itemsSorted, ['date', 'team'])

  return itemsSorted
}
