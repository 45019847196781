<template lang="pug">
DetailSmall(:detail="taskdetail")
  template(slot="title") task detail.
  template(slot="form")
    v-layout(row)
      v-flex
        v-textarea(
          label="assignment"
          v-model="assignment"
        )
        v-select(
          label="assignee"
          v-model="assignee"
          :items="users"
          item-text="username"
        )
        v-select(
          label="team"
          v-model="team"
          :items="teams"
          return-object
          item-text='name'
        )
  template(slot="controls")
    DetailControls(
      @save="save"
      @cancel="cancel"
      @remove="remove"
    )
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import DetailSmall from '@/components/base/detail-small-modal'
import DetailControls from '@/components/base/detail-controls-simple'

export default {
  components: { DetailControls, DetailSmall },
  computed: {
    ...mapFields('scheduling', ['taskdetail']),
    ...mapFields('productionInstances', {
      assignment: 'item.task.assignment',
      assignee: 'item.task.assignee',
      team: 'item.team',
    }),
    users () { return this.$store.state.users.items },
    teams () {
      return this.$store.getters['dashboards/teams']
    }
  },
  methods: {
    async save () {
      await this.$store.dispatch('productionInstances/_UPDATE')
      this.cancel()
    },

    async remove () {
      await this.$store.dispatch('productionInstances/_DELETE')
      this.cancel()
    },

    cancel () {
      this.taskdetail = false
    }
  }
}
</script>
