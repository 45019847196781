<template lang="pug">
div
  //- span order: {{item.dashboard.orderDisplayName}} /
  span  job#: {{item.dashboard.jobNumber}} /
  span.font-weight-bold.black--text
    span  press: {{item.press.name}} /
    span  dd date:
    span(v-if="item.dates.dropDeadOverride")  {{formatDate(item.dates.dropDeadOverride)}}
    span(v-else)  {{formatDate(item.dates.dropDead)}}
    span / profit: {{ Math.round(item.decorationcost)}}
</template>

<script>
import moment from 'moment'
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    formatDate (date) {
      if (!date) { return '' }
      return moment(date).format('L')
    }

  }
}
</script>
