<template lang="pug">
div
  v-list-tile(@click='taskDetail(item)').teal.lighten-5
    v-list-tile-content
      v-list-tile-title.title.font-weight-bold
        span(v-if='item.task.isComplete').pa-2.teal.white--text realizado!
        span task --> {{ item.task.assignee }}
      v-list-tile-sub-title.subheading {{ item.task.assignment }}

  TaskDetail
</template>
<script>
import TaskDetail from './taskdetail'
import { mapFields } from 'vuex-map-fields'
import { ProductionInstance } from '@/models'

export default {
  props: {
    item: { type: Object, required: true }
  },
  components: {
    TaskDetail,
  },
  computed: {
    ...mapFields('productionInstances', {
      pi: 'item'
    }),
    ...mapFields('scheduling', ['taskdetail']),
  },
  methods: {
    taskDetail (item) {
      this.pi = new ProductionInstance(item)
      this.taskdetail = true
    }
  },
}
</script>
