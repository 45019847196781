<template lang="pug">
  v-list-tile(
    :class="tileClass(item)"
    avatar
  ).list-item
    v-list-tile-avatar(v-if="item.dashboard.image.url" tile @click="select(item)")
      img(:src="item.dashboard.image.url")
    v-list-tile-content(@click="select(item)")
      v-list-tile-title
        v-layout(row)
          v-flex(xs6) {{item.dashboard.designName}} - {{item.dashboard.jobSubtitle}} -
            span.font-weight-bold  {{item.location.template.name}}
          v-flex(xs6)
            JobProgressBar(:progress="item.dashboard.jobProgress" v-if="progressToggle")
            v-layout(row v-else)
              v-flex.pink--text(xs12 v-if="item.minutes.override")
                | minutes: {{item.minutes.override}}
              v-flex(xs12 v-else)
                | minutes: {{item.minutes.default}}
      v-list-tile-sub-title
        v-layout(row)
          v-flex(xs6)
            PiSubtitle(:item='item')
          v-flex(xs6 v-if="progressToggle")
            PiProgressBar(:progress="item.progress")
    v-list-tile-action
      TeamPicker(
        v-if="teamToggle && ['ProductionLead', 'Admin'].includes(currentUser.role)"
        :_idProductionInstance="item._id"
        :team="item.team"
      )
      PriorityPicker(
        v-if="priorityToggle && ['ProductionLead', 'Admin'].includes(currentUser.role)"
        :pi="item"
      )
      DatePicker(
        v-if='!priorityToggle && !teamToggle && currentUser.role === "Admin"'
        :dateOriginal="item.dates.schedule"
        :pi="item"
      )
</template>
<script>
import DatePicker from './date-picker'
import PriorityPicker from './priority-picker'
import TeamPicker from './team-picker'
import PiSubtitle from './pi-subtitle'
import JobProgressBar from '@/components/JobProgressBar'
import PiProgressBar from '@/components/PiProgressBar'
import { mapFields } from 'vuex-map-fields'

export default {
  props: ['item'],
  components: {
    DatePicker,
    PriorityPicker,
    TeamPicker,
    JobProgressBar,
    PiProgressBar,
    PiSubtitle,
  },
  computed: {
    ...mapFields('scheduling', ['priorityToggle', 'teamToggle', 'progressToggle', 'taskdetail']),
    currentUser () {
      return this.$store.state.users.item
    }
  },
  methods: {
    tileClass (item) {
      if (item.dashboard.isPressCheck) {
        return 'highlight-presscheck'
      }
      const ddDate = item.dates.dropDeadOverride
        ? item.dates.dropDeadOverride
        : item.dates.dropDead
      const isDateWarning = ddDate ? ddDate < item.dates.schedule : false
      if (isDateWarning) {
        return 'highlight-warning'
      }
    },
    select (item) {
      this.$router.push({ path: '/dashboards/schedule/' + item._id })
    }


  }
}
</script>

<style lang="stylus" scoped>
.highlight-warning
  background-color #FFCDD2
.highlight-presscheck
  background-color #C8E6C9
</style>
