<template lang="pug">
v-list-tile.list-item.black
  v-list-tile-content.white--text
    v-list-tile-title.font-weight-black.title 
      v-layout(row) 
        v-flex {{ item._tempDate }}
        v-flex profit: ${{ Math.round(item.profit) }}
  v-list-tile-action.white--text
    v-btn(@click='addTask(item)' dark) add task

</template>
<script>
import moment from 'moment'
import { mapFields } from 'vuex-map-fields'
import { ProductionInstance } from '@/models'

export default {
  props: ['item'],
  computed: {
    ...mapFields('scheduling', ['taskdetail']),
    ...mapFields('productionInstances', {
      pi: 'item',
    }),
  },
  methods: {

    formatDate (date) {
      if (!date) {
        return ''
      }
      return moment(date).format('L')
    },

    async addTask (item) {
      const instance = new ProductionInstance({
        status: 'wip',
        dates: {
          schedule: item.dates.schedule
        },
        task: {
          isTask: true,
          assignment: '',
          assignee: '',
          isComplete: false
        },
        order: '5e9630431edc6e63c65158c4',
        job: '5e9630431edc6e63c65158c4',
        design: '5e9630431edc6e63c65158c4'
      })

      const post = await this.$store.dispatch('productionInstances/postOne', {
        data: instance
      })
      this.pi = post
      this.taskdetail = true
    },

  },
}
</script>

